import { graphql, useStaticQuery } from 'gatsby';
import { ILineObject } from '@lce/intl-types/src/ILineObject';
import { IDatoImage } from '@lce/intl-types/src/IDatoImage';
import { Direction } from '@lce/intl-types/src/IDirection';
import removeLine from '@lce/intl-util/src/removeLine';
import useFranchisees from '@lce/gatsby-theme-multi-site/src/api/FranchiseeId/hooks/useFranchisees';
import { useSelectedFranchiseeId } from '@lce/gatsby-theme-multi-site/src/api/FranchiseeId';
import franchiseeFilter from '@lce/intl-util/src/FranchiseeFilter';
import franchiseeDirection from '@lce/intl-util/src/FranchiseeDirection';

interface IQualityStoryPageProps {
  qualityStoryPage: {
    nodes: IDatoQualityStoryPage[];
  };
}

interface IDatoQualityStoryPage {
  locale: string;  
  showButton: boolean;
  button: IDatoQualityStoryPageButton;
  video: IDatoQualityStoryPageVideo;
  banner: IDatoQualityStoryPageBanner;
  modal: IDatoQualityStoryPageModal;
}

interface IDatoQualityStoryPageButton {
  label: string;
  url: string;
}

interface IDatoQualityStoryPageVideo {
  title: string;
  url: string;
}

interface IDatoQualityStoryPageBanner {
  bannerName: string;
  image: IDatoImage;
  cents?: string;
  subCents?: string;
  dollars?: string;
  currencySymbol?: IDatoImage;
  subTitle: string;
  script?: string;
  superScript?: string;
  title: ILineObject[]; 
}

interface IDatoQualityStoryPageModal {
  title: string;
  legalText: string;
}

// eslint-disable-next-line max-lines-per-function
const useQualityStory = (locale: string) => {
  const data: IQualityStoryPageProps = useStaticQuery(graphql`
    query UseQualityStoryPageMsQuery {
      qualityStoryPage: allDatoCmsQualityStoryPage {
        nodes {
          locale
          franchisee {
            name
            originalId
          }
          showButton
          button {
            label
            url
          }
          video {
            title
            url
          }
          banner{
            bannerName
            cents
            dollars
            currencySymbol {
              alt
              url
            }
            image {
              alt
              url
            }
            script
            subCents
            subTitle
            superScript
            title {
              line
            }
          }
          modal {
            title
            legalText
          }
        }
      }
    }
  `);

  const { franchiseeId: SelectedFranchiseeId } = useSelectedFranchiseeId(locale);
  const { franchisees } = useFranchisees(locale);

  const direction: Direction = franchiseeDirection(franchisees, SelectedFranchiseeId);
  
  const filteredQualityStoryPage = franchiseeFilter<IDatoQualityStoryPage>(data?.qualityStoryPage?.nodes, SelectedFranchiseeId, locale);

  const cleanedBanner = {
    ...filteredQualityStoryPage?.banner,
    title: removeLine(filteredQualityStoryPage?.banner?.title),
    direction,
    locale,
  };

  return {
    showButton: filteredQualityStoryPage?.showButton,
    qualityStoryButton: {
      ...filteredQualityStoryPage?.button,
    },
    qualityStoryVideo: {
      ...filteredQualityStoryPage?.video,
    },
    qualityStoryBanner: {
      ...cleanedBanner,
    },
    qualityStoryModalContent: {
      ...filteredQualityStoryPage?.modal,
    },
  };
};

export default useQualityStory;
