import * as React from 'react';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import Layout from '@lce/gatsby-theme-multi-site/src/ui/ConnectedLayout';

import useQualityStory from '../@lce/gatsby-theme-multi-site/api/QualityStory';
import QualityStoryPage from '../@lce/intl-ui/PageComponents/QualityStory';

const NuestraHistoriaDeCalidadPage: React.FC<IDefaultPageProps> = props => {
  const { locale } = props.pathContext;
  const { showButton, qualityStoryButton, qualityStoryVideo, qualityStoryBanner, qualityStoryModalContent } = useQualityStory(locale);

  return (
    <Layout {...props}>
      <QualityStoryPage 
        banner={qualityStoryBanner} 
        button={qualityStoryButton} 
        content={qualityStoryModalContent} 
        showButton={showButton}
        video={qualityStoryVideo} 
      />
    </Layout>
  );
};

export default NuestraHistoriaDeCalidadPage;

