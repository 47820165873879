/* eslint-disable max-lines-per-function */
import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import Button from '@lce/slice_v2/Elements/Button';
import DefaultBanner, { IDefaultBannerProps } from '@lce/intl-ui/src/Patterns/Banners/DefaultBanner';
import Container from '@lce/intl-ui/src/Layout/Container';
import LeavingSiteModal, { ILeavingSiteModalContent } from '@lce/gatsby-theme-multi-site/src/ui/Patterns/Modals/LeavingSiteModal';

import * as Styled from './QualityStory.style';

export interface IQualityStory {
  showButton: boolean;
  button: IQualityStoryButton;
  video: IQualityStoryVideo; 
  banner: IDefaultBannerProps;
  content: ILeavingSiteModalContent;
  showModal?: boolean;
};

export interface IQualityStoryButton {
  label: string;
  url: string;
};

export interface IQualityStoryVideo {
  title: string;
  url: string;
};

const QualityStoryPage: React.FC<IQualityStory> = ({ 
  showButton,
  button, 
  video, 
  banner, 
  content, 
  showModal = false,
}) => {
  const [isOpen, setOpen] = React.useState(showModal);

  return (
    <>
      <Container data-testid="quality-story-page" sx={Styled.Container} >
        {showButton && 
          <Box data-testid="button-box" sx={Styled.ButtonWrapper}>
            <Button
              data-testid="external-site-button"
              onClick={() => setOpen(true)}
              sx={Styled.Button}
            >
              {button.label}
            </Button>
          </Box>
        }
        <Box data-testid="video-player" sx={Styled.VideoContentWrapper} >
          <Box sx={Styled.VideoWrapper}>
            <iframe
              allow="autoplay; fullscreen"
              allowFullScreen
              data-testid="video-iframe"
              frameBorder="0"
              height="100%"
              src={video.url}
              style={{ position: 'absolute', top: 0, left: 0 }}
              title={video.title}
              width="100%"
            />
          </Box>
        </Box>
        <Box sx={Styled.BannerWrapper}>
          <DefaultBanner {...banner} />
        </Box>
      </Container>
      <LeavingSiteModal 
        content={content}
        isOpen={isOpen}
        onClose={() => setOpen(false)} 
        url={button.url}
      >
        {button.label}
      </LeavingSiteModal>
    </>
  );
};

export default QualityStoryPage;

