import { SxStyleProp } from 'theme-ui';

export const Container: SxStyleProp = { 
  mb: '12px', 
  px: '16px', 
};

export const ButtonWrapper: SxStyleProp = { 
  width: '100%', 
  display: 'flex', 
  justifyContent: 'center', 
};

export const Button: SxStyleProp = {
  borderRadius: '12px',
  alignItems: 'center',
  p: '12px 20px',
  mx: 'auto',
  textAlign: 'center',
  textDecoration: 'none',
  fontFamily: 'primary',
  fontSize: ['20px', '20px', '24px'],
  textTransform: 'uppercase',
  lineHeight: 1,
  fontWeight: 'bold',
  color: 'white',
  backgroundColor: 'primary',
  cursor: 'pointer',
  ':hover': {
    textDecoration: 'underline',
  },
};

export const VideoContentWrapper: SxStyleProp = { 
  mx: 'auto',
  width: '100%', 
  my: '10px',
};

export const VideoWrapper: SxStyleProp = { 
  position: 'relative', 
  paddingTop: '56.25%', 
  height: 0, 
};

export const BannerWrapper: SxStyleProp = {
  backgroundColor: 'primary', 
  mb: '24px',
};
